import { patchLicense, postLicense } from '@/api/public';
import { convertToSalesPeopleOptions, convertToSelectOptions } from '@/utils/common';
import { Button, Col, Divider, Input, InputNumber, Row, Select, message } from 'antd';
import React, { useState } from 'react';

import FileUploader from '@/components/Common/FileUploader';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { licenseEnumAtom } from '@/store/license';
import { productsEnumAtom } from '@/store/products';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';

interface LicenseInfoProps {
  licenseId?: string | null;
  data?: LicenseDetailResponse | null;
}

const LicenseInfo = ({ licenseId, data }: LicenseInfoProps) => {
  const isUsers = !!data?.seller;

  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [licenseFilterInfo, setLicenseFilterInfo] = useAtom(licenseEnumAtom);
  const [productFilterInfo, setProductFilterInfo] = useAtom(productsEnumAtom);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);

  const [certificateData, setCertificateData] = useState({
    index: 0,
    id: 'certificateImage',
    name: '차량등록증',
    value: data?.certificationImage || '',
  });

  const [licenseData, setLicenseData] = useState({
    index: 11,
    id: 'license',
    name: '번호판 허가증',
    value: data?.licenseImage || '',
  });

  const convertLicenseFilterInfoToSelectOptions = (licenseFilterInfo: LicenseFilterInfoType) => {
    return {
      licenseSalesType: convertToSelectOptions(licenseFilterInfo.licenseSalesType),
      licenseType: convertToSelectOptions(licenseFilterInfo.licenseType),
      locate: convertToSelectOptions(licenseFilterInfo.locate),
      status: convertToSelectOptions(licenseFilterInfo.status),
      useClassification: convertToSelectOptions(licenseFilterInfo.useClassification),
    };
  };

  const [selectedValue, setSelectedValue] = useState<SelectedLicenseValue>({
    year: data?.year ? String(data?.year) : '',
    tons: data?.tons || '',
    status: data?.status?.code || null,
    licenseType: data?.licenseType?.code || null,
    licenseSalesType: data?.licenseSalesType?.code || 'TRADE',
    useClassification: data?.useClassification?.code || null,
    locate: data?.locate?.code || null,
    fee: data?.fee || null,
    price: data?.price || null,
    insuranceRate: data?.insuranceRate || null,
    licenseImage: data?.licenseImage || '',
    certificationImage: data?.certificationImage || '',
    salesPeople: data?.salesPeople?.id ? String(data?.salesPeople?.id) : null,
    productNumber: Number(data?.productNumber) || null,
  });

  const selectOptions = convertLicenseFilterInfoToSelectOptions(licenseFilterInfo);
  const salesPeopleOptions = convertToSalesPeopleOptions(productFilterInfo.salesPeople);

  const onChangeInput = (name: string, value: string) => {
    setIsDisabledEditBtn(false);

    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const patchLicenseMutation = useMutation(
    (requestData: LicenseRegisterUpdateRequest) => patchLicense(String(licenseId), requestData),
    {
      onSuccess: () => {
        message.success('상세 정보가 수정되었습니다.', 2);
        setIsDisabledEditBtn(true);
      },
      onError: () => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const postLicenseMutation = useMutation(postLicense, {
    onSuccess: () => {
      message.success('번호판 정보가 등록되었습니다.', 2);
      setIsDisabledEditBtn(true);
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const isValueChanged = (dataValue: any, selectedValue: any) => dataValue !== selectedValue;

  const onClickRegisterBtn = () => {
    if (licenseId) {
      const request: LicenseRegisterUpdateRequest = {
        certificationImage: isValueChanged(data?.certificationImage || '', certificateData?.value)
          ? certificateData?.value
          : undefined,
        fee: isValueChanged(data?.fee, selectedValue.fee) ? selectedValue.fee : undefined,
        insuranceRate: isValueChanged(data?.insuranceRate, selectedValue.insuranceRate)
          ? selectedValue.insuranceRate
          : undefined,
        licenseImage: isValueChanged(data?.licenseImage || '', licenseData?.value) ? licenseData?.value : undefined,
        licenseSalesType: isValueChanged(data?.licenseSalesType?.code, selectedValue.licenseSalesType)
          ? selectedValue.licenseSalesType
          : undefined,
        licenseType: isValueChanged(data?.licenseType?.code, selectedValue.licenseType)
          ? selectedValue.licenseType
          : undefined,
        locate: isValueChanged(data?.locate?.code, selectedValue.locate) ? selectedValue.locate : undefined,
        price: isValueChanged(data?.price, selectedValue.price) ? selectedValue.price : undefined,
        licenseStatus: isValueChanged(data?.status?.code, selectedValue.status) ? selectedValue.status : undefined,
        tons: isValueChanged(data?.tons, selectedValue?.tons) ? selectedValue?.tons : undefined,
        useClassification: isValueChanged(data?.useClassification?.code, selectedValue.useClassification)
          ? selectedValue.useClassification
          : undefined,
        year: isValueChanged(data?.year, Number(selectedValue.year)) ? Number(selectedValue.year) : undefined,
        salesPeopleId: isValueChanged(data?.salesPeople?.id, Number(selectedValue.salesPeople))
          ? Number(selectedValue.salesPeople)
          : undefined,
        productNumber: selectedValue?.productNumber ? selectedValue.productNumber : null,
      };

      patchLicenseMutation.mutate(request);
    } else {
      const request: LicenseRegisterUpdateRequest = {
        certificationImage: certificateData?.value,
        fee: selectedValue?.fee,
        insuranceRate: selectedValue?.insuranceRate,
        licenseImage: licenseData?.value,
        licenseType: selectedValue?.licenseType,
        licenseSalesType: selectedValue?.licenseSalesType,
        locate: selectedValue?.locate,
        price: selectedValue?.price,
        licenseStatus: selectedValue?.status,
        tons: selectedValue?.tons,
        useClassification: selectedValue?.useClassification,
        year: Number(selectedValue?.year),
        salesPeopleId: Number(selectedValue?.salesPeople),
        productNumber: selectedValue?.productNumber,
      };

      postLicenseMutation.mutate(request);
    }
  };

  const isShowLocate =
    selectedValue.licenseSalesType == 'RENTAL' ||
    (selectedValue.licenseSalesType == 'TRADE' && selectedValue.licenseType == 'GENERAL_CARGO');

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            연식<span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="연식"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.year || ''}
              onChange={(e) => onChangeInput('year', String(e.target.value))}
              disabled={isUsers}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            톤수<span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="톤수 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.tons}
              onChange={(value) => onChangeInput('tons', String(value))}
              disabled={isUsers}
              wheel={false}
            />
            <span className="mr-4">t</span>
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>상태</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="상태"
              optionFilterProp="children"
              options={selectOptions.status}
              value={selectedValue.status}
              onChange={(value) => onChangeInput('status', value)}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            거래방식<span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              placeholder="거래방식"
              optionFilterProp="children"
              options={selectOptions.licenseSalesType}
              value={selectedValue.licenseSalesType}
              onChange={(value) => onChangeInput('licenseSalesType', value)}
              disabled={isUsers}
            />
          </div>
        </Col>

        {selectedValue.licenseSalesType == 'TRADE' ? (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>
                번호판 종류<span className="font-normal text-red-500 ml-1">(필수)</span>
              </div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  className="mr-4"
                  style={{ width: '100%', minWidth: 86, maxWidth: 240 }}
                  placeholder="번호판 종류"
                  optionFilterProp="children"
                  options={selectOptions.licenseType}
                  value={selectedValue.licenseType}
                  onChange={(value) => onChangeInput('licenseType', value)}
                  disabled={isUsers}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>
                번호판 용도<span className="font-normal text-red-500 ml-1">(필수)</span>
              </div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  className="mr-4"
                  style={{ width: '100%', minWidth: 76, maxWidth: 160 }}
                  placeholder="번호판 용도"
                  optionFilterProp="children"
                  options={selectOptions.useClassification}
                  value={selectedValue.useClassification}
                  onChange={(value) => onChangeInput('useClassification', value)}
                  disabled={isUsers}
                />
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>
                보험요율<span className="font-normal text-red-500 ml-1">(필수)</span>
              </div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <InputNumber
                  className="mr-1"
                  placeholder="보험요율 입력"
                  style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                  value={selectedValue?.insuranceRate}
                  onChange={(value) => onChangeInput('insuranceRate', String(value))}
                  wheel={false}
                />
                <span className="mr-4">%</span>
              </div>
            </Col>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>
                지입료<span className="font-normal text-red-500 ml-1">(필수)</span>
              </div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Input
                  className="mr-1"
                  placeholder="지입료"
                  style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                  value={selectedValue?.fee || ''}
                  onChange={(e) => onChangeInput('fee', String(e.target.value))}
                />
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {isShowLocate && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>
                지역<span className="font-normal text-red-500 ml-1">(필수)</span>
              </div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  className="mr-4"
                  style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                  placeholder="지역"
                  optionFilterProp="children"
                  options={selectOptions.locate}
                  value={selectedValue.locate}
                  onChange={(value) => onChangeInput('locate', value)}
                  disabled={isUsers}
                />
              </div>
            </Col>
          </>
        )}

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            가격<span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="가격 입력"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.price}
              onChange={(value) => onChangeInput('price', String(value))}
              wheel={false}
            />
            <span className="mr-4">만원</span>
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 제시번호</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="제시번호"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.productNumber || ''}
              onChange={(e) => onChangeInput('productNumber', String(e.target.value))}
            />
          </div>
        </Col>
        {!isUsers && (
          <>
            <Col className="gutter-row" span={3}>
              <div style={labelStyle}>
                영업 담당자<span className="font-normal text-red-500 ml-1">(필수)</span>
              </div>
            </Col>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <Select
                  style={{ width: '100%', minWidth: 86, maxWidth: 200 }}
                  placeholder="영업 담당자"
                  optionFilterProp="children"
                  options={salesPeopleOptions}
                  value={selectedValue.salesPeople}
                  onChange={(value) => onChangeInput('salesPeople', String(value))}
                />
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량등록증</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <FileUploader
            type="license"
            isShowImage={false}
            imageType="CERTIFICATE_IMAGE"
            truckNumber={''}
            fileData={certificateData}
            setFileData={setCertificateData}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            isDisabled={isUsers}
          ></FileUploader>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>번호판 허가증</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <FileUploader
            type="license"
            isShowImage={false}
            imageType="LICENSE"
            truckNumber={''}
            fileData={licenseData}
            setFileData={setLicenseData}
            fileNameMaxLength={340}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
            isDisabled={isUsers}
          ></FileUploader>
        </Col>
      </Row>
      <Divider orientation="left"></Divider>
      <div className="flex justify-end">
        <Button
          type="primary"
          className="bg-[#1890ff] mx-2 w-20"
          disabled={isDisabledEditBtn}
          onClick={onClickRegisterBtn}
        >
          {licenseId ? '수정하기' : '등록하기'}
        </Button>
      </div>
    </>
  );
};

export default LicenseInfo;
