import { atom } from 'jotai';

export const productsEnumAtom = atom({
  axis: [],
  loaded: [],
  manufacturerCategoriesWithModels: [],
  productsType: [],
  salesType: [],
  status: [],
  transmission: [],
  locate: [],
  fuel: [],
  color: [],
  maintenanceCategories: [],
  normalOption: [],
  additionalOption: [],
  breakOption: [],
  salesPeople: [],
});
