import BasicInfo from '@/components/Vehicles/Common/Register/BasicInfo';
import { CONSIGNMENT } from '@/const/vehicles';
import React from 'react';
import { Tabs } from 'antd';

const ConsignmentRegister = () => {
  let tabs: Tab[] = [
    {
      key: 'info',
      label: '차량 정보',
      children: <BasicInfo salesType={CONSIGNMENT} />,
    },
  ];
  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 위탁 차량 - 차량 상세</h2>
      <h3 className="text-gray-8">
        차량관리 / 위탁 차량 /<span className="font-semibold"> 차량 상세</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default ConsignmentRegister;
