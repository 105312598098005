import { getPurchaseAccompanyingServices } from '@/api/public';
import PurchaseAccompanyingServicesFilterSelector from '@/components/PurchaseAccompanyingServices/PurchaseAccompanyingServicesFilterSelector';
import PurchaseAccompanyingServicesSearchResult from '@/components/PurchaseAccompanyingServices/PurchaseAccompanyingServicesSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const PurchaseAccompanyingServicesList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedPurchaseAccompanyingServicesFilterValue>({
    status: null,
  });

  const [searchResultData, setSearchResultData] = useState<PurchaseAccompanyingServicesResponse | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const getPurchaseAccompanyingServicesData = async (page?: number) => {
    const { status } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      status: status || '',
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getPurchaseAccompanyingServices(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getPurchaseAccompanyingServicesData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <>
      <h2 className="hidden">차량 구매 동행 서비스 신청 관리 페이지 - 신청 내역</h2>
      <h3 className="text-gray-8">
        차량 구매 동행 서비스 관리 / <span className="font-semibold">차량 구매 동행 서비스 신청 내역</span>
      </h3>

      <PurchaseAccompanyingServicesFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getPurchaseAccompanyingServicesData={() => getPurchaseAccompanyingServicesData(currentPage)}
      ></PurchaseAccompanyingServicesFilterSelector>
      <PurchaseAccompanyingServicesSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getPurchaseAccompanyingServicesData={() => getPurchaseAccompanyingServicesData(currentPage)}
      ></PurchaseAccompanyingServicesSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default PurchaseAccompanyingServicesList;
