import { getTransferAgencyServices } from '@/api/public';
import TransferAgencyServicesFilterSelector from '@/components/TransferAgencyServices/TransferAgencyServicesFilterSelector';
import TransferAgencyServicesSearchResult from '@/components/TransferAgencyServices/TransferAgencyServicesSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const TransferAgencyServicesList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedTransferAgencyServicesFilterValue>({
    status: null,
  });

  const [searchResultData, setSearchResultData] = useState<TransferAgencyServicesResponse | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const getTransferAgencyServicesData = async (page?: number) => {
    const { status } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      status: status || '',
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getTransferAgencyServices(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getTransferAgencyServicesData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <>
      <h2 className="hidden">이전대행서비스 신청 관리 페이지 - 신청 내역</h2>
      <h3 className="text-gray-8">
        이전대행서비스 관리 / <span className="font-semibold">이전대행서비스 신청 내역</span>
      </h3>

      <TransferAgencyServicesFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getTransferAgencyServicesData={() => getTransferAgencyServicesData(currentPage)}
      ></TransferAgencyServicesFilterSelector>
      <TransferAgencyServicesSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getTransferAgencyServicesData={() => getTransferAgencyServicesData(currentPage)}
      ></TransferAgencyServicesSearchResult>

      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default TransferAgencyServicesList;
