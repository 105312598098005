import { deleteProduct, patchProductPerchase, patchProductsDetail } from '@/api/public';
import { formatNumberWithComma, getFormatDate } from '@/utils/common';
import { Button, Modal, Table, TableColumnsType, message } from 'antd';
import React, { useEffect, useState } from 'react';

import BasicPopup from '@/components/Common/BasicPopup';
import ExcelDownloadButton from '@/components/Common/Button/ExcelDownloadButton';
import SortingButton from '@/components/Common/SortingButton';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { ASSURANCE, CONSIGNMENT } from '@/const/vehicles';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface DataType {
  id: number;
  key: number;
  productsNumber: number;
  truckName: string;
  truckNumber: string;
  status: string;
  type?: string;
  salesType?: string;
  manufacturerName: string;
  modelName: string;
  firstRegistrationDate: string;
  loadedInnerLength: number;
  transmission: string;
  distance: string;
  price: number;
  salesPeople?: string;
  createdDate: string;
  modifiedDate: string;
  productManage: ProductPurchaseManage;
  isDelete: boolean;
}

const VehicleSearchResult = ({
  selectedFilterValue,
  data,
  getTruckData,
  ascending,
  setAscending,
  sortType,
  setSortType,
}: ProductResponseDataProps) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [excelDownloadParams, setExcelDownloadParams] = useState<ProductExcelParams>({});
  const [isDisabledPurchaseBtn, setIsDisabledPurchaseBtn] = useState(false);
  const [purchaseManageComment, setPurchaseManageComment] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [isManageMemoModalOpen, setIsManageMemoModalOpen] = useState(false);

  const onclickSortingButton = () => {
    if (setAscending) {
      setAscending(!ascending);
    }
    setSortType('CREATED_DATE');
  };

  const onclickModifiedDateSortingButton = () => {
    if (setAscending) {
      setAscending(!ascending);
    }
    setSortType('MODIFIED_DATE');
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: '제시번호',
      dataIndex: 'productsNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '차량번호',
      dataIndex: 'truckNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
    },
    {
      title: '매물 유형',
      dataIndex: 'type',
    },
    {
      title: '판매 유형',
      dataIndex: 'salesType',
    },
    {
      title: '제조사',
      dataIndex: 'manufacturerName',
    },
    {
      title: '모델',
      dataIndex: 'modelName',
    },
    {
      title: '연식',
      dataIndex: 'firstRegistrationDate',
    },
    {
      title: '적재함 길이',
      dataIndex: 'loadedInnerLength',
    },
    {
      title: '변속기',
      dataIndex: 'transmission',
    },
    {
      title: '주행거리',
      dataIndex: 'distance',
    },
    {
      title: '판매가격',
      dataIndex: 'price',
    },
    {
      title: <SortingButton title="등록일자" isAscending={ascending} onclick={onclickSortingButton} />,
      dataIndex: 'createdDate',
    },
    {
      title: <SortingButton title="수정일자" isAscending={ascending} onclick={onclickModifiedDateSortingButton} />,
      dataIndex: 'modifiedDate',
    },
    {
      title: '영업 담당자',
      dataIndex: 'salesPeople',
    },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete == true ? '삭제' : '미삭제'),
    },
    {
      title: '관리메모',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <>
          {record?.productManage?.comment ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-1"
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                }}
              >
                {record?.productManage?.comment}
              </p>

              <Button onClick={(e) => onClickMemoBtn(e, record)}>수정</Button>
            </>
          ) : (
            <Button onClick={(e) => onClickMemoBtn(e, record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const onClickMemoBtn = (e: React.MouseEvent<HTMLElement, MouseEvent>, data: ProductListDataType) => {
    setPurchaseManageComment(data.productManage?.comment);
    setSelectedProductId(String(data.id));
    setIsManageMemoModalOpen(true);
    e.stopPropagation();
  };

  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      productsNumber: item.productsNumber,
      truckName: item.truckName,
      truckNumber: item.truckNumber,
      status: item.status?.desc || '',
      type: item.type?.desc,
      salesType: item.salesType?.desc,
      manufacturerName: item.manufacturerName,
      modelName: item.modelName,
      firstRegistrationDate: getFormatDate(String(item.firstRegistrationDate), ['year', 'month'], true),
      loadedInnerLength: item.loadedInnerLength,
      transmission: item.transmission?.desc || '',
      distance: formatNumberWithComma(String(item.distance)),
      price: item.price,
      createdDate: item.createdDate,
      modifiedDate: item.modifiedDate ? item.modifiedDate : '-',
      salesPeople: item.salesPeople?.id ? `${item.salesPeople?.name}(${item.salesPeople?.phoneNumber})` : '-',
      productManage: item.productPurchaseManage,
      isDelete: item.isDelete,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const [firstSelectedId] = newSelectedRowKeys;
    const record = data?.data.find((item) => item.id === firstSelectedId);
    setIsDisabledPurchaseBtn(record?.status?.code != 'SALE' || record?.type?.code != 'SPEED');
  };

  const rowProps = (record: DataType) => ({
    onClick: () => {
      navigate(`/vehicles/all/${record.id}`);
    },
  });

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      mutate(String(firstSelectedId));
    } else {
      message.error('차량이 선택되지 않았어요. 삭제할 차량을 선택해주세요.');
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const onClickAssurancePurchaseBtn = () => {
    const request = {
      id: selectedRowKeys,
      salesType: ASSURANCE,
    };
    patchProductPerchaseMutation.mutate(request);
    setIsOpenPopup(false);
  };

  const onClickConsignmentPurchaseBtn = () => {
    const request = {
      id: selectedRowKeys,
      salesType: CONSIGNMENT,
    };
    patchProductPerchaseMutation.mutate(request);
    setIsOpenPopup(false);
  };

  const { mutate } = useMutation(deleteProduct, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getTruckData();
    },
    onError: (error: AxiosError) => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const patchProductPerchaseMutation = useMutation(
    (requestData: { salesType: string }) => patchProductPerchase(String(selectedRowKeys), requestData),
    {
      onSuccess: ({ data }) => {
        message.success('매입 처리가 완료되었어요.', 2);
        if (data.id) {
          navigate(`/vehicles/assurance/${data.id}`);
        }
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const hasSelected = selectedRowKeys.length > 0;

  const showConfirm = () => {
    setIsOpenPopup(true);
  };

  const onClickPurchase = () => {
    // const [firstSelectedId] = selectedRowKeys;
    setIsOpenModal(true);
    // patchProductPerchaseMutation.mutate(Number(firstSelectedId));
  };

  useEffect(() => {
    setExcelDownloadParams({
      productNumber: selectedFilterValue?.productNumber ? String(selectedFilterValue?.productNumber) : '',
      truckName: selectedFilterValue?.truckName || '',
      productsStatus: selectedFilterValue?.status || '',
      type: selectedFilterValue?.productsType || '',
      manufacturerId: selectedFilterValue?.manufacturerCategories || '',
      modelId: selectedFilterValue?.model || '',
      tons: selectedFilterValue?.tons ? String(selectedFilterValue?.tons) : '',
      loaded: selectedFilterValue?.loaded || '',
      loadedInnerLength: selectedFilterValue?.loadedInnerLength ? String(selectedFilterValue?.loadedInnerLength) : '',
      axis: selectedFilterValue?.axis || '',
      transmission: selectedFilterValue?.transmission || '',
      startDate: selectedFilterValue?.startDate || '',
      endDate: selectedFilterValue?.endDate || '',
      salesType: selectedFilterValue.salesType || '',
    });
  }, [selectedFilterValue]);

  const patchPurchaseManageCommentMutation = useMutation(
    (requestData: { id: string; productPurchaseManageComment: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('관리메모가 작성되었어요.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const handleManageSave = () => {
    if (selectedProductId) {
      const request: any = {};
      request.id = selectedProductId;
      request.productPurchaseManageComment = purchaseManageComment;
      patchPurchaseManageCommentMutation.mutate(request);
    }
    setIsManageMemoModalOpen(false);
  };

  const handleManageModalClose = () => {
    setIsManageMemoModalOpen(false);
  };

  const onChangeComment = (comment: string) => {
    setPurchaseManageComment(comment);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체차량</span> {data?.totalElements}대
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
          <div>
            <Button
              className="mr-2"
              onClick={onClickPurchase}
              disabled={patchProductPerchaseMutation.isLoading || isDisabledPurchaseBtn}
              loading={patchProductPerchaseMutation.isLoading}
            >
              매입하기
            </Button>
            <ExcelDownloadButton
              apiPath="/admin/v1/products/excel"
              params={excelDownloadParams}
              buttonText="엑셀 다운로드"
            ></ExcelDownloadButton>
            {/* <Button danger onClick={showConfirm}>
              삭제
            </Button> */}
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onRow={rowProps}
          pagination={false}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 차량을 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>

      <Modal title="차량 매입" open={isOpenModal} footer={null} onCancel={handleCloseModal} centered>
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '8px', height: '36px', lineHeight: '36px' }}>
            <Button
              style={{
                backgroundColor: '#1890ff', // 파란 배경색
                color: '#fff', // 흰색 글자색
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                marginRight: '8px',
              }}
              onClick={onClickAssurancePurchaseBtn}
            >
              진단차량으로 매입
            </Button>
          </div>
          <div style={{ marginBottom: '8px', height: '36px', lineHeight: '36px' }}>
            <Button
              style={{
                backgroundColor: '#52c41a', // 초록 배경색
                color: '#fff', // 흰색 글자색
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                marginRight: '8px',
              }}
              onClick={onClickConsignmentPurchaseBtn}
            >
              위탁차량으로 매입
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title="메모 보기"
        open={isManageMemoModalOpen}
        onCancel={handleManageModalClose}
        footer={[
          <Button key="close" onClick={handleManageModalClose}>
            닫기
          </Button>,
          <Button key="save" type="primary" className="bg-[#1890ff] mx-2" onClick={handleManageSave}>
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={purchaseManageComment}
          onChange={(e) => onChangeComment(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default VehicleSearchResult;
