import { getProducts } from '@/api/public';
import SpeedFilterSelector from '@/components/Vehicles/Speed/SpeedFilterSelector';
import SpeedSearchResult from '@/components/Vehicles/Speed/SpeedSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const SpeedList = () => {
  const restoredParam = new URLSearchParams(sessionStorage.getItem('speedListParam') || '');
  const productNumberString = restoredParam.get('productNumber');
  const productNumber = productNumberString !== null ? parseInt(productNumberString, 10) : null;
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedFilterValue>({
    productNumber: productNumber,
    status: restoredParam.get('productsStatus') || null,
    productPurchaseManageStatus: restoredParam.get('productPurchaseManageStatus') || null,
    startDate: restoredParam.get('startDate') || null,
    endDate: restoredParam.get('endDate') || null,
    isDelete: restoredParam.get('isDelete') || null,
  });
  const [ascending, setAscending] = useState(false);

  const [searchResultData, setSearchResultData] = useState<ProductsResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const getTruckData = async (page?: number) => {
    const { productNumber, truckNumber, status, productPurchaseManageStatus, startDate, endDate, isDelete } =
      selectedFilterValue;
    const queryParams = new URLSearchParams({
      productNumber: productNumber ? String(productNumber) : '',
      truckNumber: truckNumber ? truckNumber : '',
      productsStatus: status || '',
      type: 'SPEED',
      productPurchaseManageStatus: productPurchaseManageStatus || '',
      startDate: startDate || '',
      endDate: endDate || '',
      sortType: 'SPEED_REQUEST_TIME',
      ascending: String(ascending),
      isDelete: isDelete ? String(isDelete) : '',
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getProducts(queryParams);
    sessionStorage.setItem('speedListParam', queryParams.toString());
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getTruckData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);
  useEffect(() => {
    getTruckData();
  }, [ascending]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 매입견적 차량</h2>
      <h3 className="text-gray-8">
        차량관리 / <span className="font-semibold">매입견적 차량</span>
      </h3>
      <SpeedFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getTruckData={() => getTruckData(currentPage)}
      ></SpeedFilterSelector>
      <SpeedSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getTruckData={() => getTruckData(currentPage)}
        ascending={ascending}
        setAscending={setAscending}
      ></SpeedSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};
export default SpeedList;
