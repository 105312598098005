import { Button, DatePicker, Input, Select } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import React from 'react';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  selectedFilterValue: MemberSelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<MemberSelectedFilterValue>>;
  getMemberData: () => void;
}
const FilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getMemberData }: SearchProps) => {
  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const convertMemberFilterInfoToSelectOptions = () => {
    return {
      isAgreeMarketing: [
        { value: 'true', label: '동의' },
        { value: 'false', label: '미동의' },
      ],
      socialType: [
        { value: 'KAKAO', label: '카카오' },
        { value: 'NAVER', label: '네이버' },
      ],
    };
  };
  const selectOptions = convertMemberFilterInfoToSelectOptions();

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      name: '',
      phoneNumber: '',
      startDate: '',
      endDate: '',
      socialType: '',
      isAgreeMarketing: '',
      ascending: false,
    });
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Search
          className="mr-4"
          placeholder="이름 입력"
          allowClear
          onSearch={getMemberData}
          style={{ width: 180 }}
          value={selectedFilterValue?.name || ''}
          onChange={(e) => handleInputStringChange('name', e.target.value)}
        />
        <Search
          className="mr-4"
          placeholder="휴대폰 번호 입력"
          allowClear
          onSearch={getMemberData}
          style={{ width: 180 }}
          value={selectedFilterValue?.phoneNumber || ''}
          onChange={(e) => handleInputStringChange('phoneNumber', e.target.value)}
        />

        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="마케팅 동의 여부"
          optionFilterProp="children"
          options={selectOptions.isAgreeMarketing}
          value={selectedFilterValue?.isAgreeMarketing || null}
          onChange={(value) => onChangeSelect('isAgreeMarketing', value)}
        />

        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="로그인 타입"
          optionFilterProp="children"
          options={selectOptions.socialType}
          value={selectedFilterValue?.socialType || null}
          onChange={(value) => onChangeSelect('socialType', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <div className="w-full">
          <span className="mr-2">가입일시</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>

        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getMemberData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default FilterSelector;
